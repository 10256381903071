import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <div className='container not-found'>
      <SEO title='404: Not found' />
      <h1>Not found</h1>
      <p>You are lost.</p>
    </div>
  </Layout>
)

export default NotFoundPage
